import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Product } from '../products';
import { ActivatedRoute, Router } from '@angular/router';

// USING A SERVICE INSTEAD
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
  providers: [ProductService]
})
export class ProductDetailsComponent implements OnInit, OnDestroy {

  public id: number;
  public products: Product[];
  private sub: any;
  public domain: String = "http://arab-medicine.com/";

  prodIdSnapshot: number;

  constructor(private productService: ProductService, private route: ActivatedRoute, private router: Router, private location: Location) { }

  ngOnInit() {
    // Get the products
    if (localStorage.hasOwnProperty('products')) {
      var storageProducts = JSON.parse(localStorage.getItem('products'));
      this.products = storageProducts;
    } else {
      console.log('Calling the server, no data in the local storage');
      this.productService.getProductsPromise().then(
        (val) => this.products = this.productService.products,
        (err) => console.error(err)
      );
    }

    // set the product id from the route
    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id'];
    });
  }

  loadPictures(search) {
    //$('#loading').show();
    this.productService.getImagesPromise(search).then(
      (val) => {
        //$('#loading').hide();
      },
      (err) => console.error(err)
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  goBack(id) {
    //window.history.back();
    //this.location.back();
    this.router.navigate(['product-list'], {fragment: 'anchor'+id});
  }
}
