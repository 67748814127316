import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../products';

@Pipe({
  name: 'filterProduct'
})
export class FilterProductPipe implements PipeTransform {

  // Search with product name
  transform(allProducts: Product[], searchText: string, hotFilter: boolean, coldFilter: boolean,  wetFilter: boolean,  dryFilter: boolean): any {

    // Check parameters
    if (!allProducts) return [];
    if (!searchText) return allProducts;

    console.log('FilterProductPipe');

    // Filter values
    return allProducts.filter(it => {
      if(hotFilter && it.primary.toString() != 'hot') return false;
      if(coldFilter && it.primary.toString() != 'cold') return false;
      if(wetFilter && it.primary.toString() != 'wet') return false;
      if(dryFilter && it.primary.toString() != 'dry') return false;
      if(searchText) return it.name.toString().toLowerCase().includes(searchText.toString().toLowerCase());
      return true;
    });
  }
}
