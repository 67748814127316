import { Pipe, PipeTransform } from '@angular/core';
import { Product } from '../products';

@Pipe({
  name: 'selectedProduct'
})

export class SelectedProductPipe implements PipeTransform {
  // Search with productId
  transform(allProducts: Product[], productId: number): any {
    if (allProducts == null) {
      return new Product(0, '', '', '', '', '', '');
    } else {
      var filtredProducts = allProducts.filter(p => Number(p.id) === Number(productId));
      //console.log(filtredProducts);
      return filtredProducts
    }
  }
}
