
export class Product {

  public id: number;
  public name: string;
  public nameEN: string;
  public description: string;
  public primary: string;
  public secondary: string;
  public image: string;

  public cssClassName: string;

    constructor(
      public idInput: number,
      public nameInput: string,
      public nameENInput: string,
      public descriptionInput: string,
      public primaryInput: string,
      public secondaryInput: string,
      public imageInput: string

    ) {

      this.id = idInput;
      this.name = nameInput;
      this.nameEN = nameENInput;
      this.description = descriptionInput;
      this.primary = primaryInput;
      this.secondary = secondaryInput;
      this.image = imageInput;

      if(String(this.primary) == 'dry'){
        this.cssClassName = 'bg-danger text-white'; // red
      } else if(String(this.primary) == 'cold'){
        this.cssClassName = 'bg-dark text-white'; // blue
      } else if(String(this.primary) == 'hot'){
        this.cssClassName = 'bg-warning text-black'; // yellow
      } else if(String(this.primary) == 'wet'){
        //this.cssClassName = 'bg-primary text-white'; // black
      } else {
        this.cssClassName = '';
      }

      // if(String(this.hot) == 'TRUE' && String(this.wet) == 'TRUE'){
      //   this.cssClassName = 'bg-danger text-white'; // red
      // } else if(String(this.hot) == 'FALSE' && String(this.wet) == 'FALSE'){
      //   this.cssClassName = 'bg-dark text-white'; // blue
      // } else if(String(this.hot) == 'TRUE' && String(this.wet) == 'FALSE'){
      //   this.cssClassName = 'bg-warning text-black'; // yellow
      // } else if(String(this.hot) == 'FALSE' && String(this.wet) == 'TRUE'){
      //   this.cssClassName = 'bg-primary text-white'; // black
      // } else {
      //   this.cssClassName = '';
      // }


    }
}
