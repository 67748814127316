import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class PwaService {

  public promptEvent;

  constructor(private swUpdate: SwUpdate) {
    // if any update available
    swUpdate.available.subscribe(event => {
      if (this.askUserToUpdate()) {
        window.location.reload();
      }
    });
    // to put an install button
    window.addEventListener('beforeinstallprompt', event => {
      this.promptEvent = event;
    });
  }

  askUserToUpdate() {
    return confirm("مرحبا هل ترغب في تحميل الاصدار الجديد للتطبيق ؟");
  }
}
