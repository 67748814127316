import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailsComponent } from './product-details/product-details.component';

import { ProductService } from './services/product.service';
import { PwaService }       from './services/pwa.service';

import { SelectedProductPipe } from './pipes/selected-product.pipe';
import { FilterProductPipe } from './pipes/filter-product.pipe';
import { HttpErrorHandler }     from './http-error-handler.service';
import { MessageService }       from './message.service';

import { AppRoutingModule } from './app-routing.module';

import * as $ from 'jquery';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
//import { NgxPopper } from 'angular-popper';
import { LazyLoadImagesModule } from 'ngx-lazy-load-images';
import { HomePageComponent } from './home-page/home-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { HumorGeneratorComponent } from './humor-generator/humor-generator.component';

@NgModule({
  declarations: [
    AppComponent,
    ProductListComponent,
    ProductDetailsComponent,
    SelectedProductPipe,
    FilterProductPipe,
    HomePageComponent,
    AboutUsComponent,
    IntroductionComponent,
    HumorGeneratorComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    LazyLoadImagesModule
  ],
  providers: [
    ProductService,
    PwaService,
    HttpErrorHandler,
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
