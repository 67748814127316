import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

import { ProductListComponent } from './product-list/product-list.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HumorGeneratorComponent } from './humor-generator/humor-generator.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { AboutUsComponent } from './about-us/about-us.component';

// ROUTES
const appRoutes: Routes = [

    { path: '', redirectTo: 'home-page', pathMatch: 'full' },
    { path: 'home-page', component: HomePageComponent },
    { path: 'introduction', component: IntroductionComponent },
    { path: 'humor-generator', component: HumorGeneratorComponent },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'product-list', component: ProductListComponent },
    { path: 'product-details/:id', component: ProductDetailsComponent },
    { path: '404', component: HomePageComponent},
    { path: '**', redirectTo: '/home-page'}
];

const routerOptions: ExtraOptions = {
  enableTracing: false,
  //useHash: false,
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes,routerOptions)
  ],
  exports: [
      RouterModule
  ]
})
export class AppRoutingModule { }
