import { Injectable, ErrorHandler, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Product } from '../products';
import { HttpErrorHandler, HandleError } from '../http-error-handler.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'my-auth-token'
  })
};

@Injectable()
export class ProductService {
    sheetUrl = '/assets/Unani%20Food%20DB%20Final%20-%2004-03-2020.csv'
    //'https://docs.google.com/spreadsheets/d/1bKhIOqg5o8gjMSBgvzfxNxqS82edS51h1wXmkqXDNWo/gviz/tq?tqx=out:json&tq&gid=0';
    //'https://spreadsheets.google.com/feeds/cells/1bKhIOqg5o8gjMSBgvzfxNxqS82edS51h1wXmkqXDNWo/1/public/full?alt=json';
    //sheetUrl = 'https://spreadsheets.google.com/feeds/cells/1uqUzQSqJjt2OhTqrOEyhM_3HHo-syMsHsSpRw6m58T4/1/public/full?alt=json';
    googleImagesUrl = 'https://serpapi.com/search.json?engine=google&google_domain=google.com&tbm=isch';
    public products: Product[];
    private handleError: HandleError;

    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler) {
      // Init the error handler
      this.handleError = httpErrorHandler.createHandleError('ProductService');
    }

    setProducts(products: Product[]){
      this.products = products;
    }

    /* GET heroes whose name contains search term */
    getRawProducts(): Observable<any> {
      return this.http.get(this.sheetUrl, {responseType: 'text'}).pipe(
          catchError(this.handleError<Product[]>('getRawProducts', []))
        );
    }

    /* GET heroes whose name contains search term */
    getRawImages(search): Observable<any> {
      return this.http.get<any[]>(this.googleImagesUrl + '&q='+ search ).pipe(
          catchError(this.handleError<Product[]>('getRawProducts', []))
        );
    }

    getProductsPromise(): Promise<any> {
      return new Promise((resolve, reject) => {
        this.getRawProducts().subscribe(data => {
          if(data != null){
            // var entry = data.feed.entry;
            // this.products = [];
            // for (var i = 7; i < entry.length; i += 7){
            //   console.log(i);
            //   var product = new Product(
            //       entry[i].content.$t,
            //       entry[i+1].content.$t,
            //       entry[i+2].content.$t,
            //       entry[i+3].content.$t,
            //       entry[i+4].content.$t,
            //       entry[i+5].content.$t,
            //       entry[i+6].content.$t
            //     );
            //     this.products.push(product);
            //     console.log(product);
            //   }
            //   // set to the local storage
            //   localStorage.setItem('products', JSON.stringify(this.products));
            //   // finish
            //   console.log('Get products successfully from backend');
            //   resolve('done');
            this.products = [];
            let csvToRowArray = data.split("\n");
            for (let index = 1; index < csvToRowArray.length - 1; index++) {
              let row = csvToRowArray[index].split(",");
              this.products.push(new Product( row[0], row[1], row[2], row[3], row[4], row[5], row[6]));
            }

            console.log(this.products);
            // set to the local storage
            localStorage.setItem('products', JSON.stringify(this.products));
            // finish
            console.log('Get products successfully from backend');
            resolve('done');

          }
          // Error
          reject('error empty data or network issue');
        });
      });
    }

    getImagesPromise(search): Promise<any> {
      return new Promise((resolve, reject) => {
        this.getRawImages(search).subscribe(data => {
          if(data != null){
            // finish
            console.log(data);
            resolve('done');
          }
          // Error
          reject('error empty data or network issue');
        });
      });
    }


}
