import { Component, OnInit } from '@angular/core';
import { Product } from '../products';
import { Router } from '@angular/router';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as $ from 'jquery';

// USING A SERVICE INSTEAD
import { ProductService } from '../services/product.service';
import { PwaService } from '../services/pwa.service';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
  providers: [ProductService]
})

export class ProductListComponent {

  products: Product[];
  filteredProducts: Product[];
  searchText: string;
  hotFilter: boolean;
  coldFilter: boolean;
  wetFilter: boolean;
  dryFilter: boolean;

  constructor(private productService: ProductService, public Pwa: PwaService, public app: AppComponent) { }

  installPwa() {
    this.Pwa.promptEvent.prompt();
    //return confirm("مرحبا هل ترغب في تحميل الاصدار الجديد للتطبيق ؟");
  }

  // check if the product are locally stored
  ngOnInit() {
    //this.installServiceWorker();
    // init
    this.searchText = '';
    this.hotFilter = false;
    this.coldFilter = false;
    this.wetFilter = false;
    this.dryFilter = false;
    // Load products
    if (localStorage.hasOwnProperty('products')) {
      var storageProducts = JSON.parse(localStorage.getItem('products'));
      //console.log(storageProducts);
      this.products = storageProducts;
      this.productService.setProducts(this.products);
      $('#loading').hide();
    } else {
      if (this.productService.products == null) {
        this.productService.getProductsPromise().then(
          (val) => {
            this.filteredProducts = this.productService.products;
            $('#loading').hide();
          },
          (err) => console.error(err)
        );
      } else {
        this.filteredProducts = this.productService.products;
        $('#loading').hide();
      }
    }
    // filteredProducts
    this.filteredProducts = this.products;
  }

  installServiceWorker() {
    // force add the pwa
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('ngsw-worker.js')
        .then(function(registration) {
          console.log("Service Worker Registered", registration);
        })
        .catch(function(err) {
          console.log("Service Worker Failed to Register", err);
        })
    }
  }

  refreshProductList() {
    this.filteredProducts = [];
    $('#loading').show();
    this.productService.getProductsPromise().then(
      (val) => {
        this.filteredProducts = this.productService.products;
        this.hotFilter = false;
        this.coldFilter = false;
        this.wetFilter = false;
        this.dryFilter = false;
        $('#loading').hide();
      },
      (err) => console.error(err)
    );
  }

  filter(humor) {
    if (this.searchText == '' && humor == '') {
      this.filteredProducts = this.products;
    } else if (this.searchText == '' && humor != '' && !(document.getElementById(humor + 'Filter') as HTMLInputElement).checked) {
      this.filteredProducts = this.products;
    } else {
      // select just the last one
      document.querySelectorAll('.checkbox input').forEach(checkbox => {
        if (checkbox.id != humor + 'Filter') (checkbox as HTMLInputElement).checked = false;
      });
      // do the filter
      this.filteredProducts = this.products.filter(it => {

        var humorFilter = '';
        document.querySelectorAll('.checkbox input[type=checkbox]:checked').forEach(checkbox => {
          humorFilter = (checkbox as HTMLInputElement).name;
        });

        // if its check filter
        if (humorFilter != '' && it.primary.toString() != humorFilter) {
          return false;
        }
        // if its text filter
        if (this.searchText != '') {
          return (
            it.name.toLowerCase().includes(this.searchText.toLowerCase())
            ||
            it.nameEN.toLowerCase().startsWith(this.searchText.toLowerCase())
          );
        }
        // default yes
        return true;
      }
      );
    }
  }



}
