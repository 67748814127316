import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  constructor(public router:Router){}

  /* Set the width of the sidebar to 250px and the left margin of the page content to 250px */
  toggleOpenNav() {
    var menuSidebar = document.getElementById("sidebar");
    if (menuSidebar.style.width == "250px") {
      menuSidebar.style.width = "0px";
    } else {
      menuSidebar.style.width = "250px";
    }
  }

  openRoute(url:String){
    this.router.navigate([url]);
    document.getElementById("sidebar").style.width = "0px";
    return false;
  }
}
